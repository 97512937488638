.crm-layout .logo {
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin: 16px;
  color: #fff;
  font-weight: bold;
  line-height: 32px;
  padding: 0 12px;
  a {
    color: #fff;
  }
}

.site-layout .site-layout-background {
  background: #fff;
  padding: 24px;
  margin-top: 24px;
  min-height: 300px;
}
.site-layout .site-layout-background-flexible {
  background: #fff;
  padding: 24px;
  margin-top: 24px;
}

.ant-descriptions-item-content {
  .ant-table {
    margin-left: 0 !important;
  }
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  width: 100%;
}

.ant-form-item-label > label {
  white-space: normal !important;
}

// .ant-layout-sider-children {
//   display: flex;
//   flex-direction: column;
//   .ant-menu {
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     position: relative;
//     padding-bottom: 52px;
//     .ant-menu-item:last-child {
//       position: absolute;
//       bottom: 0;
//       width: 100%;
//       left: 0;
//     }
//   }
// }

.iso-editor .block-editor-writing-flow {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.block-editor-media-placeholder__upload-button {
  display: none !important;
}
.trip-itinerary-block {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}

.trip-addon-block {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}
.trip-remark-block {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}
.trip-agreement-block {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}

.trip-agreement-content-block + .trip-agreement-content-block {
  padding-top: 10px;
  border-top: 1px solid #cccccc;
}

.row-disabled {
  color: #ee6055;
}
.row-enabled {
  color: #40916c;
}
